<template>
  <div
    class="px-4 h-[80px] flex items-center"
  >
    <!-- avatar image -->
    <div
      class="w-[44px] h-[44px] rounded-[17px] my-auto bg-white overflow-hidden"
    >
      <el-avatar
        shape="square"
        :src="avatarUrl"
      />
    </div>
    <!-- user information -->
    <div
      class="w-auto max-w-[100px] h-[34px] grid grid-rows-2 gap-1 justify-center mx-4 my-auto"
    >
      <div class="overflow-hidden whitespace-nowrap text-ellipsis leading-[17px] text-white">
        {{ userInfo.userName }}
      </div>
      <div class="overflow-hidden whitespace-nowrap text-ellipsis text-[12px] leading-[17px] text-[#97AAB9]">
        {{ userInfo.phoneNumber }}
      </div>
    </div>
    <!-- log-out icon -->
    <div
      class="flex cursor-pointer ml-auto"
      @click="logout"
    >
      <SvgIcon
        class="text-xl"
        icon-class="log-out"
      />
    </div>
  </div>
  <GeneralDelDialog
    v-model:visible="dialogVisible.visible"
    :message="dialogMessage"
    :title="dialogTitle"
    :confirm-func="confirmLogOut"
  />
</template>

<script setup lang="ts">
interface UserInfo {
  userName: string | null,
  phoneNumber: string | null,
}
const props = withDefaults(defineProps<{
  avatarUrl: string,
  userInfo: UserInfo
}>(), {
  avatarUrl: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
})

onMounted(() => {
  console.log(props.userInfo)
})
/** =====  退出登录-start  ====== **/
function logout() {
  dialogVisible.visible = true
}
function confirmLogOut() {
  $utils.oauthLogout()
}
const dialogTitle = ref<string>('提示')
const dialogMessage = ref<string>('<span>确定要退出登录吗</span>')
const dialogVisible = reactive<{
  visible: boolean
}>({
  visible: false
})
/** =====  退出登录-end  ====== **/
</script>

<style scoped lang="scss">

</style>
