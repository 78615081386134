<template>
  <div class="h-16 px-8 flex items-center justify-between">
    <div class="w-[317px]">
      <el-input
        v-model="searchValue"
        placeholder="请输入"
        clearable
        @keydown.enter="search"
      >
        <template #prefix>
          <SvgIcon
            icon-class="search"
            class="search text-xl cursor-pointer"
            @click="search"
          />
          <!-- <Icon
          name="flat-color-icons:search"
          class="text-xl cursor-pointer"
          @click="search"
        /> -->
        </template>
      </el-input>
    </div>
    <div class="flex justify-end">
      <el-button
        type="text"
        :disabled="!isRenderPrintStatus"
        @click="printer"
      >
        <el-tooltip
          content="打印状态"
          placement="bottom"
          effect="dark"
        >
          <el-icon
            class="text-xl text-assist1"
          >
            <SvgIcon
              icon-class="menu_printer"
            />
          </el-icon>
        </el-tooltip>
      </el-button>
      <el-divider
        direction="vertical"
        class="h-6"
      />
      <el-badge
        :is-dot="showTotal"
      >
        <el-button
          type="text"
          @click="taskStatus"
        >
          <el-tooltip
            content="消息中心"
            placement="bottom"
            effect="dark"
          >
            <el-icon
              class="text-xl text-assist1"
            >
              <SvgIcon
                icon-class="notice-bell"
              />
            </el-icon>
          </el-tooltip>
        </el-button>
      </el-badge>
      <el-divider
        direction="vertical"
        class="h-6"
      />
      <SelectTeachingMaterial
        class="flex-1 ml-auto"
        type="secondMenu"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
// import { Ref } from 'vue'

// const searchValue = inject($inject.databaseSearchValue) as Ref<string>
// const watchSearch = inject($inject.databaseWatchSearch)
// const emit = defineEmits(['changeSearchValue'])
// function handlerInput(val: string) {
//   if (watchSearch) {
//     watchSearch.value = val
//   }
// }
const route = useRoute()
/** =====  消息中心-start  ===== **/
const useTaskStatus = $stores.taskStatus.useTaskStatus()
// 展示红点累计数据
const showTotal = computed(() => {
  const { newGenTotal } = useTaskStatus
  return Boolean(newGenTotal)
})
function taskStatus() {
  navigateTo('/taskStatus')
}
/** =====  消息中心-end  ===== **/

/** ========== 打印状态 start ========== **/
const noRenderPrintStatus = ['index-printStatus']
const isRenderPrintStatus = computed(() => !noRenderPrintStatus.includes(route.name as string))
function printer() {
  navigateTo('/printStatus')
}
/** ========== 打印状态 end ========== **/
const { commonSearchValue, setCommonSearchValue } = $stores.common.useCommonStore()

const searchValue = computed({
  get: () => commonSearchValue.text,
  set: (val) => {
    setCommonSearchValue(val)
  }
})

function search() {
  $utils.mitt.emit('commonSearchValue')
}
</script>

<style scoped lang="scss">

</style>
