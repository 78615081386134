<template>
  <div
    class="h-screen flex"
  >
    <div>
      <el-menu
        :default-active="defaultActive"
        text-color="#97AAB9"
        :default-openeds="['/teachingManagement', '']"
        active-text-color="#fff"
        background-color="#002039"
        class="main_menu w-[240px] h-full overflow-auto flex flex-col"
        @select="handleSelect"
      >
        <div class="menu-item-bg">
          <UserAvatar
            :avatar-url="userAvatar"
            :user-info="userInfo"
          />
        </div>
        <div
          class="flex-1 overflow-auto py-3.5"
          style="scrollbar-width: none;"
        >
          <template
            v-for="item in menuItemList"
            :key="item.path"
          >
            <el-sub-menu
              v-if="item.children?.length"
              :index="item.path"
            >
              <template #title>
                <SvgIcon
                  :icon-class="item.icon"
                  class="text-xl mr-3"
                />
                <span>{{ item.label }}</span>
              </template>
              <el-menu-item
                v-for="itm in item.children"
                :key="itm.path"
                :index="item.path + itm.path"
              >
                <SvgIcon
                  :icon-class="itm.icon"
                  class="text-xl mr-3"
                />
                <span>{{ itm.label }}</span>
              </el-menu-item>
            </el-sub-menu>

            <el-menu-item
              v-else
              :index="item.path"
            >
              <SvgIcon
                :icon-class="item.icon"
                class="text-xl mr-3"
              />
              <span>{{ item.label }}</span>
            </el-menu-item>
          </template>
        </div>
      </el-menu>
    </div>
    <div
      class="flex-1 flex flex-col overflow-auto"
    >
      <SwitchAndSearch />

      <div
        class="flex-1 bg-[#f8f9fb] p-5 overflow-auto"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const defaultActive = ref()
const currentRoute = ref()
const routeList = ref<string[]>([
  '/home',
  '/teachingManagement',
  '/teachingManagement/group',
  '/teachingManagement/student',
  '/teachingManagement/exam',
  '/teachingManagement/teacher',
  '/teachingManagement/homework/group',
  '/teachingManagement/homework/person',
  '/prepareLessons',
  '/database/accordChapters',
  '/settings/accountSettings',
  '/settings/printerSettings'
])
const routeListMap: { [key: string]: string } = {
  '/teachingManagement/homework/person': '/teachingManagement/homework/group',
  '/database/accordKnowledges': '/database/accordChapters',
  '/database/accordTestPaper': '/database/accordChapters',
  '/database/accordWrongQuestion': '/database/accordChapters'
}

function initMenuHighLight() {
  currentRoute.value = useRoute().path
  const hasRouteMap = routeListMap[currentRoute.value]

  if (hasRouteMap) {
    defaultActive.value = hasRouteMap
  } else if (routeList.value.includes(currentRoute.value)) {
    defaultActive.value = currentRoute.value
  } else {
    const matches = routeList.value.filter(item => currentRoute.value.includes(item))
    if (matches.length > 0) {
      // 选择最长的匹配项
      defaultActive.value = matches.reduce((a, b) => a.length > b.length ? a : b)
    }
  }
}

/** =====  用户信息 start  ===== **/
const userStore = $stores.user.useUserStore()
const userInfo = computed(() => {
  const { teacherUserInfo } = userStore
  return {
    userName: teacherUserInfo?.name ?? '未知',
    phoneNumber: teacherUserInfo?.phone ?? '未知'
  }
})
const userAvatar = ref<string>('https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png')
onMounted(() => {
  initMenuHighLight()
})
watch(() => useRoute().path, () => {
  initMenuHighLight()
})
/** =====  用户信息 end  ===== **/

const menuItemList = reactive<nuxtLinkMenuItem[]>([
  {
    label: '首页',
    path: '/home',
    icon: 'menu_home'
  },
  {
    label: '教学管理',
    icon: 'menu_teaching',
    path: '/teachingManagement',
    children: [
      {
        label: '班级管理',
        path: '/group'
      },
      {
        label: '学生管理',
        path: '/student'
      },
      {
        label: '考试管理',
        path: '/exam'
      },
      {
        label: '教师管理',
        path: '/teacher'
      },
      {
        label: '作业管理',
        path: '/homework/group'
      }
    ]
  },
  {
    label: '备课组卷',
    path: '',
    icon: 'Web_zuocedaohanglan_beikezujuan',
    children: [
      {
        label: '备课',
        path: '/prepareLessons'
      },
      {
        label: '资料库',
        path: '/database/accordChapters'
      }
    ]
  },
  {
    label: '账号设置',
    path: '/settings/accountSettings',
    icon: 'account_setting'
  },
  {
    label: '打印机设置',
    path: '/settings/printerSettings',
    icon: 'menu_printer'
  }
])

const handleSelect = (key: string, keyPath: string[]) => {
  console.log(key, keyPath)
  navigateTo(key)
}
</script>

<style lang="scss" scoped>
:deep(.main_menu) {
  .el-menu-item.is-active {
    background-color: #09d3c9;
  }
  .el-menu-item, .el-sub-menu {
    margin: 0 10px;
    border-radius: 10px;
  }
  .el-sub-menu__title {
    color: #fff !important;
  }
}

.menu-item-bg {
  background-image: url("@/assets/img/menu-bg@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
